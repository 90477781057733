import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import expandMore from "../../../../../assets/expandMore.svg";
import editIcon from "../../../../../assets/editIcon.svg";
import arrowRight from "../../../../../assets/arrowRight.png";
import styles from "../../../css/addQuestion.module.css";
import "../../../css/survey.css";
import PropTypes from "prop-types";
import EditQuestionField from "./editQuestions";
import { connect } from "react-redux";
//import copy from "../../../../../assets/copy.svg";
import trash from "../../../../../assets/trash.svg";
import BasicModal from "../../../../../common/Modal";
import PrimaryButton from "../../../../../common/PrimaryButton";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  copySurveyQuestion,
  postAddQuestion,
  editQuestions,
  getSubCategoreyList,
  questionDelete,
} from "../../../store/actions";
import Constants from "../../../../../config";
import parse from 'html-react-parser';

const propTypes = {
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  questionArray: PropTypes.array,
  selectedValue: PropTypes.string,
  surveyDetail: PropTypes.array,
  handleDelete: PropTypes.func,
  addhandleChange: PropTypes.func,
  questionDetail: PropTypes.object,
  previewQuestionApi: PropTypes.func,
  categoryList: PropTypes.array,
  previewQuestion: PropTypes.array,
  subCategoryList: PropTypes.array,
  isMobileLayout: PropTypes.bool,
  addOptionCategory: PropTypes.func,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  isDesktopLayout: PropTypes.bool,
  ikigaiDetails: PropTypes.array
};

const typeOptions = [
  { type: "shortText", text: "Short Text" },
  { type: "scale", text: "Ratings" }
];

const ModelContent = ({ handleClose, deleteQuestion, id }) => {
  const onSave = (e) => {
    e.preventDefault();
    deleteQuestion(id);
  };

  return (
    <div>
      <div className="cardModalButton">
        <PrimaryButton
          text="Cancel"
          onClick={handleClose}
          className="cancelButton"
        />
        <PrimaryButton text="Yes" onClick={onSave} className="saveButton" />
      </div>
    </div>
  );
};
const PreviewQuestions = (props) => {
  //const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState([]);
  const [id, setId] = React.useState("");
  const [editValue, seteditValue] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [successMsg, setsuccessMsg] = React.useState(false);
  const [editSuccessMsg, setEditSuccessMsg] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    let arr = [...expanded];
    if (isExpanded) {
      if (!arr.includes(panel)) {
        arr.push(panel);
      }
    } else {
      const i = arr.indexOf(panel)
      if (i > -1) {
        arr.splice(i, 1);
      }
    }
    if (!event.target.className.includes('previewDivCol')) {
      setExpanded(arr);
    }
  };
  React.useEffect(() => {
    switch (props?.status) {
      case "QUESTION_DELETE_SUCCESS":
        props?.previewQuestionApi();
        setOpen(false);
        setsuccessMsg(true);
        break;
      case "QUESTION_DELETE_ERROR":
        break;
      case "EDIT_QUESTIONS_SUCCESS":
        setEditSuccessMsg(true);
        break;
      case "COPY_GOAL_QUESTION":
        seteditValue('');
        break;
    }
  }, [props?.status]);

  React.useEffect(() => {
    if (props?.previewQuestion.length) {
      let arr = [];
      props?.previewQuestion.map((ele) => {
        arr.push(ele.identifier);
      })
      setExpanded(arr);
    }
  }, [props?.previewQuestion])

  const handleClickOpen = (id) => {
    setsuccessMsg(false);
    setId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  /*const copyQuestion = (item) => {
    dispatch(
      props?.copySurveyQuestion({
        question: item?.question,
        questionContext: item?.questionContext,
        type: item?.type,
        questionVisibleTo: item?.questionVisibleTo === "all"
          ? "Both (Selfie,Peer)"
          : item?.questionVisibleTo === "onlySelfie"
            ? "Selfie"
            : "Peer Review",
        categoryId: item?.categoryId,
        subCategoryId: item?.subCategoryId,
        isRequired: item?.isRequired,
        surveyId: item?.identifier,
        questionArray: item?.options,
      })
    );
    props?.addhandleChange(item?.type);
  };*/

  const isHTML = (text) => {
    const htmlRegex = /<([a-z][\w-]*)([^>]*)>(.*?)<\/\1>/i;
    return htmlRegex.test(text);
  }

  return (
    <div className={styles.previewQuestionsBox}>
      {props?.previewQuestion?.map((item, index) => {
        let number = index + 1;
        return (
          <div key={index}>
            {editValue === index ? (
              <EditQuestionField
                details={item}
                copySurveyQuestion={copySurveyQuestion}
                surveyDetail={props.surveyDetail}
                addhandleChange={props.addhandleChange}
                categoryList={props?.categoryList}
                title={"Q" + number}
                questionDetail={props?.questionDetail}
                editQuestions={props?.editQuestions}
                errorMessage={props?.errorMessage}
                previewQuestionApi={props?.previewQuestionApi}
                isLoading={props?.isLoading}
                status={props?.status}
                seteditValue={(value) => seteditValue(value)}
                subCategoryList={props?.subCategoryList}
                getSubCategoreyList={props?.getSubCategoreyList}
                onClose={() => seteditValue(null)}
                selectedValue={props?.selectedValue}
                addOptionCategory={props?.addOptionCategory}
                deleteGoalTitle={props?.deleteGoalTitle}
                editGoalTitle={props?.editGoalTitle}
                ikigaiDetails={props?.ikigaiDetails}
              />
            ) : (
              <Accordion
                className={
                  index % 2 === 0 ? "activeAccordion" : "inActiveAccordion"
                }
                expanded={expanded.includes(item.identifier)}
                onChange={(item?.keyResults && item?.keyResults?.length) || !props?.isDesktopLayout ? handleChange(item.identifier) : null}
              >
                <AccordionSummary
                  expandIcon={(item?.keyResults && item?.keyResults?.length) || !props?.isDesktopLayout ? <img src={expandMore} alt="ExpandMore" style={{ marginTop: expanded.includes(item.identifier) ? '26px' : '-27px' }} /> : <span style={{ width: '11px' }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    className={styles.previewQuestionTexts}
                    sx={{ color: "text.secondary" }}
                  >
                    {/* Q{index + 1}.
                    <img
                      src={arrowRight}
                      alt="arrowRight"
                      style={{
                        margin: "0 20px 0 8px",
                      }}
                    />
                    {item?.question} */}
                    <div>
                      <div className={styles.questionFieldPreview}>
                        <div className={styles.surveyDetailSpan}>
                          Objective {index + 1}
                        </div>
                        <div>
                          <img
                            src={arrowRight}
                            alt="arrowRight"
                            className={styles.surveyDetailimg}
                          />
                        </div>
                        <div className={styles.surveyDetailQuestion}>
                          {item?.question}
                        </div>
                        <div className={styles.isDesktop} style={{ width: '15%', alignSelf: 'center', marginTop: '5px' }}>
                          <div className={styles.previewDiv}>
                            {
                              item?.brandQuestionId ?
                                null
                                :
                                <div className={styles.previewDivColum}>
                                  <img
                                    src={editIcon}
                                    className={styles.previewDivCol}
                                    alt="editIcon"

                                    onClick={() => {
                                      seteditValue(index);
                                      props.handleDelete(props.questionArray.length - 1);
                                    }}
                                  />
                                </div>
                            }
                            {/*
                            <div className={styles.previewDivColum}>
                              <img
                                src={copy}
                                className={styles.previewDivCol}
                                alt="copyImg"
                                onClick={() => copyQuestion(item)}
                              />
                            </div>
                            */}
                            {
                              (props?.surveyDetail?.status === 'PUBLISHED' && props?.reviewTeamId && props?.reviewClubbedId && props?.reviewTeamId !== '' && props?.reviewClubbedId !== '') || item?.brandQuestionId ?
                                null :
                                <div className={styles.previewDivColum}>
                                  <img
                                    src={trash}
                                    className={styles.previewDivCol}
                                    alt="trashImg"
                                    onClick={() => handleClickOpen(item?.identifier)}
                                  />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className={styles.moreSummary}>
                        {/*<div className={styles.typeSummary}>
                          <span style={{ color: '#50687d' }}>Type:</span> {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                        </div>*/}
                        <div className={styles.typeSummary}>
                          {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                        </div>
                        <div className={styles.catSummary}>
                          {item?.category_name && item?.category_name !== '' ? item?.category_name : '-'}
                          {
                            item?.subcategory_name && item?.subcategory_name !== '' ?
                              <React.Fragment>
                                <ArrowRightAltIcon style={{ color: '#fff', margin: '0 5px', fontSize: '16px' }} />
                                {item?.subcategory_name}
                              </React.Fragment> : null
                          }
                        </div>
                        {item?.ikigai ?
                          <div className={styles.ikigaiSummary}>
                            {item?.ikigai_name && item?.ikigai_name !== '' ? `Ikigai` : null}
                            {
                              item?.ikigai_name && item?.ikigai_name !== '' ?
                                <React.Fragment>
                                  <ArrowRightAltIcon style={{ color: '#fff', margin: '0 5px', fontSize: '16px' }} />
                                  {item?.ikigai_name}
                                </React.Fragment> : null
                            }
                          </div> : null
                        }
                      </div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordianInput">
                  {/* {
                    item?.questionContext && item?.questionContext !== '' ?
                      <div className={styles.explaination}>
                        <div className={styles.surveyDetailSpanSmall}>
                          {Constants.KEY_RESULTS} :
                        </div>
                        <div className={styles.surveyDetailExplain + " genericTextArea"}>
                          {parse(item?.questionContext)}
                        </div>
                      </div> : null
                  } */}
                  {
                    item?.keyResults && item?.keyResults?.length ?
                      <div className={styles.explaination}>
                        <div className={styles.surveyDetailSpanSmall}>
                          {Constants.KEY_RESULTS} :
                        </div>
                        <div>
                          {item?.keyResults.map(i => (
                            <div key={item.text} className={styles.surveyDetailExplain + " genericTextArea"}>
                              <p key={i.text}>{isHTML(i.text) ? parse(i.text) : i.text}</p>
                            </div>
                          ))}
                        </div>
                      </div> : null
                  }
                  {/*item?.type === "shortText" ? (
                    <>
                      <Input
                        placeholder="Type your answer here......."
                        inputProps={{ "aria-label": "description" }}
                      />
                      <div style={{ marginTop: "10px" }}>
                        <img src={helperText} alt="helperText" />
                      </div>
                    </>
                  ) : item?.type === "choice" ? (
                    <div className={styles.accordionMcq}>
                      {item?.options?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              state === index
                                ? styles.accordionMcqBoxActive
                                : styles.accordionMcqBox
                            }
                            onClick={() => {
                              setState(index);
                            }}
                          >
                            <div className={styles.accordionMcqAlpha}>
                              {String.fromCharCode(65 + index)}
                            </div>
                            <div className={styles.accordionMcqText}>
                              {item}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : item?.type === "boolean" ? (
                    <>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        row
                        className={styles.questionYesNoRadio}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#F56102",
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#F56102",
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </>
                  ) : item?.type === "scale" ? (
                    <RatingQuestion
                      index={index}
                      value={null}
                      isMobileLayout={props.isMobileLayout}
                    />
                  ) : (
                    <></>
                  )*/}
                  <div className={styles.isMobile}>
                    <div className={styles.previewDiv}>
                      {
                        item?.brandQuestionId ?
                          <div className={styles.previewDivColum}>
                            <img
                              src={editIcon}
                              className={styles.previewDivCol}
                              alt="editIcon"
                              onClick={() => seteditValue(index)}
                            />
                          </div>
                          :
                          null
                      }
                      {/*
                        <div className={styles.previewDivColum}>
                        <img
                          src={copy}
                          className={styles.previewDivCol}
                          alt="copyImg"
                          onClick={() => copyQuestion(item)}
                        />
                      </div>
                      */}
                      {
                        (props?.surveyDetail?.status === 'PUBLISHED' && props?.reviewTeamId && props?.reviewClubbedId && props?.reviewTeamId !== '' && props?.reviewClubbedId !== '') || item?.brandQuestionId
                          ?
                          null :
                          <div className={styles.previewDivColum}>
                            <img
                              src={trash}
                              className={styles.previewDivCol}
                              alt="trashImg"
                              onClick={() => handleClickOpen(item?.identifier)}
                            />
                          </div>
                      }
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        );
      })}
      {successMsg ? (
        <ToastNotify
          data="Objective deleted successfully"
          openAlert={true}
          type={"success"}
        />
      ) : (
        ""
      )}
      {open ? (
        <BasicModal
          open={open}
          handleClose={handleClose}
          heading="Are you sure you want to delete this objective ?"
          content={
            <ModelContent
              handleClose={handleClose}
              deleteQuestion={props?.questionDelete}
              id={id}
            />
          }
        />
      ) : (
        <></>
      )}
      {
        editSuccessMsg ?
          <ToastNotify
            data="Objective edited successfully"
            openAlert={true}
            type={"success"}
          /> : null
      }
    </div>
  );
};

const mapDispatchToProps = () => {
  return {
    copySurveyQuestion,
    postAddQuestion,
    editQuestions,
    getSubCategoreyList,
    questionDelete,
  };
};

PreviewQuestions.propTypes = propTypes;
export default connect(mapDispatchToProps)(PreviewQuestions);
