import PropTypes from "prop-types";
// import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import "../css/reviewMinutes.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DropDown from "../../../../../common/DropDown";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BasicModal from "../../../../../common/Modal";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CloseIcon from '@mui/icons-material/Close';

const propTypes = {
    graphData: PropTypes.any,
};

const QuestionView = (props) => {

    const [questionData, setQuestionData] = useState([]);

    const [selectedGoalTitle, setSelectedGoalTitle] = useState("");

    const [selfUserId, setSelfUserId] = useState("");

    const [leadUserId, setLeadUserId] = useState("");

    const [open,setOpen] = useState(false);

    const [tip,setTip] = useState([]);

    const [top,setTop] = useState([]);

    useEffect(() => {
        setQuestionData(props.stage.filter((data) => data.categoryId === props.categoryId));
        setSelectedGoalTitle(props.categoryId);
        var leadUser = props.reviewUserListItems.filter((data) => data.isLead === 1);
        var selfUser = props.reviewUserListItems.filter((data) => data.self === 1);
        setSelfUserId(selfUser.length ? selfUser[0].id : "");
        setLeadUserId(leadUser.length ? leadUser[0].id : "");
    }, [props.stage, props.categoryId])

    useEffect(() => {
        var leadUser = props.reviewUserListItems.filter((data) => data.isLead === 1);
        var selfUser = props.reviewUserListItems.filter((data) => data.self === 1);
        setSelfUserId(selfUser.length ? selfUser[0].id : "");
        setLeadUserId(leadUser.length ? leadUser[0].id : "");
    }, [props.reviewUserListItems])

    const handleChangeGoalTitle = (value) => {
        setQuestionData(props.stage.filter((data) => data.categoryId === value));
        setSelectedGoalTitle(value);
    }

    const copyQuestionAndKeyResult = (data) => {
        let text = props.textEditorValue ? props?.textEditorValue : "";
        if (data?.question) {
            text = text.concat("<br>");
            text = text.concat(data.question);
            text = text.concat("<br>");
        }
        if (data?.keyResults?.length) {
            text = text.concat("<br>");
            text = text.concat("Key Results:");
            text = text.concat("<br>");
            data?.keyResults?.map((keyResultData) => {
                text = text.concat("<br>");
                text = text.concat(keyResultData.text);
            })
        }
        props?.setTextEditorCallBack(data, text);

    }

    const ModelContent = () => {
        return (
         <div>
            <div className='flex-end'>
          <CloseIcon onClick={handleCloseModal} style={{ color: '#f56102', fontSize: '32px', cursor: 'pointer' }} title="Close" />
        </div>
        <p style={{ fontSize: '20px', fontWeight: 600 }}>{'Tip'}</p>
        {
            tip?.length ? 
            <ol style={{ color: '#1f384c', fontSize: '14px'}}>
            {
            tip?.length ? 
            tip?.map((dataQues, key) => {
                return (
                    dataQues ?
                <li key={key}>{dataQues}</li> 
                :
                null
            )
        })
        : <p>No Data available</p>
        }
        </ol>
        :
        <p>No Data available</p>
        }
       
            <p style={{ fontSize: '20px', fontWeight: 600 }}>{'Top'}</p>
            <ol style={{ color: '#1f384c', fontSize: '14px' }}>
                {
                top?.length ? top?.map((dataQues, key) => {
                    return (
                        dataQues ?
                    <li key={key}>{dataQues}</li>
                    :
                    null
                )
            })
            : <p>No Data available</p>
            }
            </ol>
         </div>
        );
      };

      const handleCloseModal = () => {
        setOpen(false);
      };

    return (
        <div style={{ marginTop: '5%' }}>
            <button className="back-btn-radar" onClick={() => props.changeViewType(0)}><KeyboardBackspaceIcon /> <span className="back-radar-text">Back To Radar</span></button>
            <DropDown
                options={props.stage?.length ? props.stage : []}
                style={{ color: "#333333" }}
                updateValue={handleChangeGoalTitle}
                placeholder="Select Objective Title"
                value={selectedGoalTitle}
                label={'Select Objective Title'}
                className="goal-title-dropdown"
            />
            {
                questionData.length ? questionData.map((stageData, first) => {
                    return (
                        <div key={first}>
                            {
                                stageData?.questionData && stageData?.questionData.length ? stageData?.questionData.map((quesData, subKey) => {
                                    return (
                                        <div key={subKey}>
                                            {
                                                <div className={'radar-block'}>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="heading-text">{quesData.question} </div>
                                                        <div style={{ position: "absolute", marginLeft: "75%", cursor: "pointer" }} title="Tip/Tops">
                                                            <LightbulbIcon style={{ color: "rgba(20, 0, 155, 0.52)" }} onClick={() => {setOpen(true);setTip(quesData.tip); setTop(quesData.top);}} />
                                                        </div>
                                                        <div style={{ position: "absolute", marginLeft: "79%", cursor: "pointer" }} title="Copy goal and key result">
                                                            <FileCopyIcon style={{ color: "#f56102" }} onClick={() => copyQuestionAndKeyResult(quesData)} />
                                                        </div>
                                                       
                                                    </div>
                                                    <div>
                                                        <div className="flex">
                                                            <div className="top-block">
                                                                <div className="small-text">
                                                                    Self
                                                                </div>
                                                                <div className="number-area1">
                                                                    {
                                                                        quesData.userRating.find(obj => obj.memberId === selfUserId) ?
                                                                            quesData.userRating.find(obj => obj.memberId === selfUserId).value == "DNK" ? "-" : quesData.userRating.find(obj => obj.memberId === selfUserId).value : '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="top-block">
                                                                <div className="small-text">
                                                                    Lead
                                                                </div>
                                                                <div className="number-area2">
                                                                    {
                                                                        quesData.userRating.find(obj => obj.memberId === leadUserId) ?
                                                                            quesData.userRating.find(obj => obj.memberId === leadUserId).value == "DNK" ? '-' : quesData.userRating.find(obj => obj.memberId === leadUserId).value : '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-text">
                                                            Peer
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                quesData.userRating.filter(obj => obj.memberId !== selfUserId && obj.memberId !== leadUserId).map((mapData, mapKey) =>
                                                                    <div key={mapKey} style={{ marginRight: '10px' }}>
                                                                        <div title={mapData.userName} className="peer-name">{mapData.userName.split(' ').length > 1 ? mapData.userName.split(' ')[0][0] : mapData.userName[0]}{mapData.userName.split(' ').length > 1 ? mapData.userName.split(' ')[1][0] : ''}</div>
                                                                        <div className="peer-value">
                                                                            {mapData.value == "DNK" ? '-' : mapData.value}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex" style={{ marginTop: '15px' }}>
                                                        <div className="cat-area"><span>{stageData.categoryName}</span> {quesData?.subCategoryName ? <><ArrowRightAltIcon style={{ margin: '-4px 5px 0' }} /> <span>{quesData.subCategoryName}</span></> : null}</div>
                                                        <div>
                                                            {
                                                                quesData.keyResults?.length ? <div className="key-results">Key Results :</div> : null
                                                            }
                                                            <div style={{ color: '#1f384c', fontSize: '14px' }}>
                                                                {
                                                                    quesData?.keyResults?.length ? quesData?.keyResults?.map((dataQues, key) => {
                                                                        return (
                                                                            <div key={key}>{dataQues?.text}</div>
                                                                        )
                                                                    })
                                                                        : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                    )
                }) :
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        No data found yet
                    </div>
            }
             {
                open ?
                <BasicModal
        open={open}
        handleCloseModal={handleCloseModal}
        className={'view-goal-modal'}
        content={
          <ModelContent
            handleCloseModal={handleCloseModal}
          />
        }
      />
                :
                null
            }
        </div>
    )
}

QuestionView.propTypes = propTypes;
export default QuestionView